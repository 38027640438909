
import React from 'react';
import AuthorAndTime from '/components/authorAndTime';
import NewsItemParts from '/components/newsItemParts';
import { isMobile } from "react-device-detect";
import fontUniOrConvertZawgyi from '/utils/fontUniOrConvertZawgyi';
import Typography from '@material-ui/core/Typography';
import { Link } from "routes";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styled from 'styled-components';
import { getCategoryColors } from '/utils/common';

const styles = theme => ({
  categoryName: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      fontSize: 18,
      color: theme.palette.whiteColor.contrastText,
      paddingLeft: 16,
      fontWeight: 'bold',
      letterSpacing: 0.5
    },
  title: {
    cursor: 'pointer',
    lineHeight: '23px',
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    position: 'relative',
    marginRight: 20,
    paddingLeft: '8px'
  },
 titleWrapper: {
  borderLeft: '9px solid #F9461E',
  padding: '7px 0px',
  display: 'inline-block',
  marginBottom: '-2px'
 }
});
class CarouselItemParts extends React.Component{
    render(){
        const { classes, item, removeAuthorAndTime, notCarousel, isFeatureCover, typoComponent } = this.props;
        const color = item.category.color ? item.category.color : '#F9461E' ;
        const suggestedPostClass = this.props.className ? this.props.className : 'article';
        let tertiaryColor = item.category.other_colors && item.category.other_colors.tertiary_color !== null ? item.category.other_colors.tertiary_color : getCategoryColors(item.category.slug).tertiary // {'#fff'};
        const mainTitle =  <Typography component="div" gutterBottom className={classes.title} style={{ cursor: isFeatureCover ? 'pointer' : 'initial'}}>
        <Typography component={typoComponent ? typoComponent : "div"} className={suggestedPostClass} style={{ background: tertiaryColor,
        boxShadow: `-8px 0 0 0 ${tertiaryColor}, 8px 0 0 0 ${tertiaryColor}`,
        color: '#0A0A0A',
        display: 'inline',
        padding: '1px 0px 6px',
        fontSize: 20,
        lineHeight: '30px',
        whiteSpace: 'initial',
        fontWeight: 'bold',}}>
            {fontUniOrConvertZawgyi(item.title)}
        </Typography>
      </Typography>
        return (
            <div style={{ position: 'absolute', bottom: notCarousel ? isMobile ? 27  : 31 : 37 , zIndex: 1 }}>
                     <div className="category-tag-gtm" >
                      <Link route={`${item.category.path}`}>
                          <a className="anchor-link">
                            <Typography className={classNames(classes.categoryName, "sub_categories")} >
                              {item.category.name}
                            </Typography>
                          </a>
                      </Link>
                     </div>
                     <div className={classes.titleWrapper}>
                       {
                        isFeatureCover ? 
                        <Link route={!item.type ? item.path : `/article/${item.slug}`}>
                            <a className="anchor-link">
                            {mainTitle}
                          </a>
                        </Link>:
                        mainTitle
                       }
                      </div>
                      { !removeAuthorAndTime &&
                        <div style={{ paddingLeft: 16 }}>
                          <AuthorAndTime item={item} linkColor="#fff" customStyle={{
                            opacity: 1, 
                            color: '#fff', 
                            fontSize: 9,
                            fontWeight: 'bold',
                            lineHeight: '11px',
                            paddingTop: 2
                          }} />
                        </div>
                      }
                  </div>
          );
        
    }
}


export default withStyles(styles)(CarouselItemParts);