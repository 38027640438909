import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import Hidden from '@material-ui/core/Hidden';
// import ContentTitle from "/components/contentTitle";
import VideoCard from "/components/Video/VideoCard";

const styles = theme => ({ 
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    }
})
function MobileCards(props) {
    const { items, maxNum, color } = props;
    return(
        items && items.length > 0 && items.map((item, index) => {
            const tvTypeIndex = item.categories && item.categories.findIndex((category) => category.type === 'TV')
            const routeName = tvTypeIndex >= 0 ? 'tvDetail' : 'videoDetail';
            
            const poster = !item.playlist_id ? item.posters.mobile_video_section_teaser : item.videos ? item.videos.posters.mobile_video_section_teaser : null;
            const maxNumber = maxNum ? index < maxNum : item
            return (
                maxNumber ?
                <React.Fragment key={index}>
                    <VideoCard 
                    color={color} 
                    routeName={routeName}
                    item={item} 
                    poster={poster} 
                    bigBtn='45px' />
                </React.Fragment>
            : null
        )})
    )
}
class MobileVideoCards extends Component {
  render() {
    const { classes, items, newsItems, routeName, color, maxNum } = this.props;
    return (
        <div>
           <MobileCards items={items} maxNum={maxNum} color={color} />
           <MobileCards items={newsItems} maxNum={maxNum} color={color} /> {/* for next more items */}
        </div>
           
    );
  }
}

export default withStyles(styles)(MobileVideoCards);