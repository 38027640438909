import React, { Component } from "react";
import Slider from "react-slick";
import DesktopFeatureCarouselItem from '/components/Desktop/DesktopFeatureCarouselItem';
export default class MobileFeatureCarousel extends Component {
  constructor(props) {
    super(props);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }
  play() {
    this.slider.slickPlay();
  }
  pause() {
    this.slider.slickPause();
  }
  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
      swipeToSlide: true,
      className: "center",
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: false
    };
    return (
      <div className="custom-slider" onTouchEnd={this.play} onTouchStart={this.pause}>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {this.props.carouselItems.length > 0 && this.props.carouselItems.map((post, index) => (
            <DesktopFeatureCarouselItem key={index} carouselItem={post} />
          ))}
        </Slider>
      </div>
    );
  }
}
