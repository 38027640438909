import React from 'react';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

function ReadAllLinkForTab(props) {
  const { readAll, color, gtmClassName, readAllClick, tabIndex } = props; 
  const gtmClass = gtmClassName ? gtmClassName : "read_all_articles_gtm"
  return (
      <Grid item xs={12}>
        <div className={gtmClass}>
            <Typography
            variant="body1"
            align="right"
            style={{
                color: color,
                cursor: 'pointer',
                paddingBottom: 20, 
                }}
                onClick={() => readAllClick(tabIndex)}
            >
            {readAll}
            <Icon style={{ paddingTop: 6 }}>
                keyboard_arrow_right
            </Icon>
            </Typography>
        </div>
      </Grid>
  );
}


export default ReadAllLinkForTab;
