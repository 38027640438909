import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Hidden from '@material-ui/core/Hidden';
// import ContentTitle from "/components/contentTitle";
import VideoCard from "/components/Video/VideoCard";

const styles = theme => ({ 
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    }
})
function DesktopCards(props){
    const { items, maxNum, color } = props;
    return(
        items && items.length > 0 && items != null && items.map((item, index) =>{
            const tvTypeIndex = item.categories && item.categories.findIndex((category) => category.type === 'TV')
            const routeName = tvTypeIndex >= 0 ? 'tvDetail' : 'videoDetail';

            const maxNumber = maxNum ? index < maxNum : item
            return(
                maxNumber ?
                <React.Fragment key={index}>
                    <Grid item xs={3}>
                        <VideoCard 
                            color={color} 
                            routeName={routeName}
                            item={item} 
                            poster={!item.playlist_id ? item.posters.video_section_teaser : item.videos ? item.videos.posters.video_section_teaser : null } 
                        />   
                    </Grid>
                </React.Fragment>
                : null
            )
        })
    )
}
class DesktopVideoCards extends Component {
  render() {
    const { classes, items, newsItems, routeName, color, maxNum } = this.props;
    return (
        <Grid container>
           <DesktopCards items={items} maxNum={maxNum} color={color} />
           <DesktopCards items={newsItems} maxNum={maxNum} color={color} /> {/* for next more items */}
        </Grid>
                
    );
  }
}

export default withStyles(styles)(DesktopVideoCards);
