
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Icon from '@material-ui/core/Icon';
import styled from 'styled-components';
import { Link } from "routes";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {getFeaturedVideoThumbnail, extractYoutubeVideoID} from '/utils/common';
import Grid from '@material-ui/core/Grid';
import SponsorFeatureBadge from '/components/sponsorFeatureBadge';
import CarouselItemParts from '/components/carouselItemParts';
import PlayIconButton from '/components/playIconButton';
import { isMobile } from "react-device-detect";
import CustomImage from '/components/customImage';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    layout: {
      margin: '-3px auto -3px',
    },
    img:{
      background: "url('https://duwun-data.sgp1.cdn.digitaloceanspaces.com/react-assets/image/default-image-1000x500.png') no-repeat center center",
      // height: 445,//
      width: '100%',
      objectFit: 'cover'
    },
    gridList: {
      width: '100%',
      // height: 445//
    },
    // shadow: {
    //   '&::before': {
    //     content: '""',
    //     width: '100%',
    //     position: 'absolute',
    //     // bottom: 4,
    //     bottom: 0,
    //     left: 0,
    //     height: 150,
    //     background: 'linear-gradient(0deg,rgba(0, 0, 0, 0.63) 20%,rgba(0, 0, 0, 0.51) 40%,rgba(0, 0, 0, 0.44) 49%,transparent)'
    //   },
    // },
    shadow: {
      content: '""',
      width: '100%',
      position: 'absolute',
      // bottom: 4,
      bottom: 0,
      left: 0,
      height: '100%',
      opacity: 0,      
      // opacity: 0.33,
      transition: 'opacity 0.5s ease-out',   
      // background: 'linear-gradient(0deg,rgba(0, 0, 0, 0.63) 20%,rgba(0, 0, 0, 0.51) 40%,rgba(0, 0, 0, 0.44) 49%,transparent)'
      '&:hover': {
        opacity: 0,
      }
    },    
    carouselPlayerIconStyle: {
      // color: '#F9461E',
      color: '#fff',
      position: 'absolute',
      bottom: 2,
      left: 2,
      fontSize: 40
    },
    carouselPlayerIconWrapperStyle: {
      width: 48,
      height: 48, 
      // background: '#fff',
      background: 'rgba(0, 0, 0, 0.4)',
      position: 'absolute',
      borderRadius: '50%',
      bottom: '50%',
      left: '50%',
      border: '2px solid #fff'
    }
});

class DesktopFeatureCarouselItem extends React.Component{
    render(){
        const { classes, carouselItem } = this.props;
        const item = carouselItem.length == 1 ? carouselItem[0] : carouselItem;
        const color = item.category.color ? item.category.color : '#F9461E' ;
        const videoUrlThumbnail = item.video_url ? getFeaturedVideoThumbnail(extractYoutubeVideoID(item.video_url))  : '';
        const videoThumbnail = item.video ? item.video.posters.thumb : '';
        const responsiveHeightCover = isMobile ? 320 : 445
        const coverImg = isMobile ? item.cover.thumb && item.cover.thumb : item.cover.teaser && item.cover.teaser
        return (
          <div className={classes.root}>
            <div className={classes.layout} >
              {/* <Lazyload
                throttle={200}
                height={300}
                offset={100}
              >     */}
              <GridList cellHeight={320} spacing={1} cols={1} rows={1} className={classes.gridList} style={{height: responsiveHeightCover}} >
                <GridListTile key={this.props.id} style={{padding: 0, height: responsiveHeightCover}}>
                  <Link route={`/article/${item.slug}`}>
                    <a className="anchor-link">
                      <div>
                      <div className={classes.shadow} style={{backgroundColor: color}}></div>
                      <SponsorFeatureBadge featured={item.featured} sponsored={item.is_sponsor} url={`/article/${item.slug}`} positionStyle={{left: 16, top: 16}}  />
                      {  item.type === 'VIDEO' || item.type === 'VIDEO_URL' || item.video || item.video_url ? 
                            (item.video || item.video_url ?
                              <div>
                                  <CustomImage 
                                    imgSrc={item.type === 'VIDEO' ? videoThumbnail : videoUrlThumbnail}
                                    imgClass={classes.img} 
                                    customStyle={`cursor: pointer; height: ${responsiveHeightCover}px;`} 
                                  />  
                                  {/* <PlayIconButton /> */}
                                  <span className={classes.carouselPlayerIconWrapperStyle}>
                                    <Icon className={classes.carouselPlayerIconStyle}>play_arrow</Icon>
                                  </span>
                              </div>
                              :
                                <CustomImage 
                                  imgSrc="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/react-assets/image/default-image-1000x500.png"
                                  imgClass={classes.img} 
                                  customStyle={`cursor: pointer; height: ${responsiveHeightCover}px;`} 
                                />
                                ): 
                                (coverImg || item.graphic.thumb ?
                                  <div className={classes.teaser}>
                                    <CustomImage 
                                      imgSrc={coverImg || item.graphic.thumb}
                                      imgClass={classes.img} 
                                      customStyle={`cursor: pointer; height: ${responsiveHeightCover}px;`} 
                                    />
                                  </div>
                                    : 
                                    <CustomImage 
                                      imgSrc="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/react-assets/image/default-image-1000x500.png"
                                      imgClass={classes.img} 
                                      customStyle={`cursor: pointer; height: ${responsiveHeightCover}px;`} 
                                    />
                                
                              )
                          }
                    
                    </div>
                    </a>
                  </Link>
                  <CarouselItemParts item={item} isFeatureCover />
                </GridListTile>
              </GridList>
              {/* </Lazyload>  */}
            </div>
          </div>
        );
        
    }
}


DesktopFeatureCarouselItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DesktopFeatureCarouselItem);
