
import React, { Component } from "react";
import Slider from "react-slick";
import DesktopFeatureCarouselItem from './DesktopFeatureCarouselItem';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  arrowStyle: {
    fontSize: 33, color: '#fff', 
    // textShadow: '1px 1px 1px #000, -1px -1px 1px #aaa', 
    cursor: 'pointer', 
    position: 'absolute',
    top: '45%',
    zIndex: 1
  }
})
function SampleNextArrow(props) {
  const { style, onClick, classes } = props;
  return (
    <Icon style={{ ...style, right: 65 }} className={classes.arrowStyle} onClick={onClick} >arrow_forward_ios</Icon>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick, classes } = props;
  return (
    <Icon style={{ ...style, left: 65 }} className={classes.arrowStyle} onClick={onClick}>arrow_back_ios</Icon>
  );
}
class DesktopFeatureCarousel extends Component {
  render() {
    const { classes } = this.props;
    var settings = {
      swipeToSlide: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      lazyLoad: true,
      autoplay: true,
      autoplaySpeed: 3000,
      centerMode: true,
      centerPadding: "110px",
      slidesToShow: 1,
      nextArrow: <SampleNextArrow classes={classes} />,
      prevArrow: <SamplePrevArrow  classes={classes} />
    };
    return (
      <div className="custom-slider">
        <Slider {...settings}>
          {this.props.carouselItems.length > 0 && this.props.carouselItems.map((post, index) => (
            <DesktopFeatureCarouselItem key={index} carouselItem={post} />
          ))}
        </Slider>
      </div>
    );
  }
}

DesktopFeatureCarousel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DesktopFeatureCarousel);