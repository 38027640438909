import React from 'react';
import VideoCardPoster from "/components/Video/VideoCardPoster";
import VideoCardParts from '/components/Video/VideoCardParts';
import withWidth from '@material-ui/core/withWidth';

class VideoCards extends React.Component {
    render(){
    const { item, poster, bigBtn, routeName, color } = this.props;
    let responsivePadding = this.props.width === 'xs' ? '0px 0px 16px' : 10
    return (
        <div className="playlistHoverWrapper">
            <div style={{padding: responsivePadding}}>
                <VideoCardPoster routeName={routeName} item={item} videoPoster={poster} bigBtn={bigBtn} />
            </div>
            <div style={{padding: '0px 16px 16px'}}>
               <VideoCardParts routeName={routeName} item={item} videoPoster={poster} color={color} />
            </div>
        </div>
  );
}
}


export default withWidth()(VideoCards);
